/* global google */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}
/**
 * Move Hero form for mobile
 */
let isMobileFormView;
function moveForm() {
  $('.home-slide').each(function () {
    let formCopy = $(this).find('.slide-caption-desktop');
    if ($(window).width() < 1024 && !isMobileFormView) {
      formCopy.insertAfter($(this).find('.home-slide__inner'));
      isMobileFormView = true;
    } else if ($(window).width() >= 1024 && isMobileFormView) {
      formCopy.insertAfter($(this).find('.slide-caption .slide-caption__text'));
      isMobileFormView = false;
    }
  });
}
/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /*
   *  This function will render a Google Map onto the selected jQuery element
   */

  function render_map($el) {
    // var
    var $markers = $el.find('.marker');
    // var styles = []; // Uncomment for map styling
    // vars
    var args = {
      zoom: parseInt($markers[0].getAttribute('data-zoom')),
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: false, // No zooming either
      scrollwheel: false, // And no scrolling
      disableDoubleClickZoom: true, // No double clicking
      disableDefaultUI: true, // Remove the controls in the top of the map
      // styles: styles, // Uncomment for map styling
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /*
   *  This function will add a marker to the selected Google Map
   */

  var infowindow;
  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: $marker.data('marker-icon'), //uncomment if you use custom marker
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  /*
   *  This function will center the map, showing all markers attached to this map
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */

  $('.acf-map').each(function () {
    render_map($(this));
  });
});
$(document).on('ready', function () {
  moveForm();
  /**
   * Action Header
   */
  // $('.header__action-btn').hover(function () {
  //   $(this).find('.action-btn--orange').addClass('animate-slideUp');
  // });

  /**
   * Testimonial Slider
   */
  $('.testimonials-slider-js').slick({
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
  /**
   * Service Slider
   */
  $('.services-slider-js').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  if (window.innerWidth < 1024) {
    $('.burger-info__address').html(function (index, oldHtml) {
      return oldHtml.replace('24/7 |', '24/7<br>');
    });
  }

  /**
   * Change form
   */

  $('.gform_drop_instructions').text('Drop a file here or click to upload');

  let newTextElement = $(
    '<span class="max-size">Maximum file size: 50MB</span>'
  );
  $('.gform_drop_instructions').after(newTextElement);

  /**
   * Coonect Footer Image
   */
  var imageSection = $('.connect-footer__image');
  var imgSrc = imageSection.data('img-url');
  imageSection.css('--img-url', 'url(' + imgSrc + ')');

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.top-bar').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('.top-bar').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  moveForm();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
